
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ProductionHistoryList from "@/ComponentsSystem/Production-history/ProductionHistoryList.vue";

export default defineComponent({
  name: "projects",
  components: {
    ProductionHistoryList,
  },
  data(): {
    DateType: string;
    startDate: string;
    endDate: string;
  } {
    return {
      DateType: "",
      startDate: "",
      endDate: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("生產履歷清單", ["生產履歷溝通數位化系統"]);
    });
  },
});
